import React from 'react'
interface ISectionTitle {
    textoColor: string;
    titulo: string;
    fondo: string;
    icono:string;
}

export const SectionTitle = (props: ISectionTitle) => {
    const {textoColor,titulo,fondo,icono } = props;
const clase1=`bg-${fondo} text-${textoColor}`;
const claseIcono=`bi bi-${icono} fw-bold display-4`;
    return (
        <>
            <section className={clase1}>

                <div className="container">

                    <div className="d-flex flex-row justify-content-center mb-2">
                        <div className="p-2">
                            <i className={claseIcono}> {titulo}</i>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

import React from 'react'

export const Maps = () => {

    const urlMap: string = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.062181304591!2d-70.56742588413073!3d-33.551760710106315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d12f9fa7b00b%3A0x345f9aeaf33d3d98!2sOld%20Sushi!5e0!3m2!1ses-419!2scl!4v1649281734486!5m2!1ses-419!2scl";


    return (
        <section id='encuentranos' className='container'>
          
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                <div id="map-container-google-11" className="z-depth-1-half map-container-6">
                    <iframe
                        src={urlMap}
                        height="450"
                        className='col-12 col-sm-12 col-md-12 col-lg-12'></iframe>
                </div>

            </div>
        </section>
    )
}
import React, { useEffect } from 'react'
import { Carousel, CarouselItem, Col, Container, Row } from 'react-bootstrap'
import { CarruselImagenes } from '../components/CarruselImagenes'
import { Servicios } from '../components/Servicios';
import { TarjetaBG } from '../components/TarjetaBG';
import { TarjetaBgImg } from '../components/TarjetaBgImg';
import { Maps } from '../components/Maps';
import { SectionSushi } from '../components/SectionSushi';
import { Footer } from '../components/Footer';
import { SectionTitle } from '../components/SectionTitle';

export const Carta = () => {

    //  useEffect(()=>{

    //      window.history.pushState({}, '', '/carta')
    //  },[]);

    return (
        <>
            <section className='mt-5'>
                <SectionSushi />
            </section>
            <Footer />
        </>
    )
}

import React, { useEffect } from 'react'
import { Carousel, CarouselItem, Col, Container, Row } from 'react-bootstrap'
import { CarruselImagenes } from '../components/CarruselImagenes'
import { Servicios } from '../components/Servicios';
import { TarjetaBG } from '../components/TarjetaBG';
import { TarjetaBgImg } from '../components/TarjetaBgImg';
import { Maps } from '../components/Maps';
import { Footer } from '../components/Footer';
import { SectionTitle } from '../components/SectionTitle';

export const Encuentranos = () => {

    // useEffect(()=>{

    //     window.history.pushState({}, '', '/encuentranos')
    
    // },[]);

    return (
        <>
            {/* <SectionTitle
                textoColor='dark'
                titulo='Encuentranos'
                fondo='dark sectionSushi mt-5'
                icono='geo-alt'
            />
            <Servicios /> */}
            <SectionTitle
                textoColor='light'
                titulo='Mapa'
                fondo='dark mt-5'
                icono='geo-alt'
            />
            <section className="sectionSushi">
                <Maps />
            </section>
            <Footer />
        </>
    )
}

import React from 'react'
import imgPlato1 from '../assets/images/cevichecamaron.jpg';
import imgPlato2 from '../assets/images/acevichado.jpg';
import imgPlato3 from '../assets/images/sachimi.jpg';
import imgPlato4 from '../assets/images/gohan.jpg';
import imgPlato5 from '../assets/images/torta.jpg';
import imgPlato6 from '../assets/images/rollsinarroz.jpg';
import imgPlato7 from '../assets/images/handRoll.jpg';
import imgLogo from '../assets/images/OLDSUSHI_01.png';
import '../assets/css/custom.css';
export const Servicios = () => {
    return (
        <>
            <main className='bg-dark text-dark py-5 sectionSushi'>
                <div className="container">
                    <div className="d-flex flex-row justify-content-center mb-3">
                        <div className="p-2 align-items-center">
                            <img src={imgLogo} alt="" className="img-fluid" width="90" height="90" />
                        </div>
                        <div className="p-2 align-items-center">
                            <h2 className='text-center display-4 fw-bold mb-5'>OLD SUSHI</h2>
                        </div>
                    </div>

                    <div className="row align-items-center text-center fw-bold">
                        <div className="col-12 bg-light rounded m-2 p-3">
                            <h1 className="display-6  ">
                                Ven a disfrutar los diferentes platos que tenemos para ti.
                            </h1>

                        </div>
                        <div className="col-12 col-lg-4 mb-3">

                            <div className="card">
                                <img src={imgPlato5} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Torta de Sushi</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-4 mb-3">

                            <div className="card">
                                <img src={imgPlato6} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Roll sin arroz</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-4 mb-3">

                            <div className="card">
                                <img src={imgPlato7} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Hand roll</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-3 mb-3">

                            <div className="card">
                                <img src={imgPlato1} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Ceviche camarón y salmón</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-3 mb-3">

                            <div className="card">
                                <img src={imgPlato2} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Acevichado</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-3 mb-3">

                            <div className="card">
                                <img src={imgPlato3} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Sashimi</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-3 mb-3">

                            <div className="card">
                                <img src={imgPlato4} className='card-img-top rounded' alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Gohan</h5>
                                    <hr />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>
        </>
    )
}

import React from 'react'
import imgLogo from '../assets/images/OLDSUSHI_01.png';
import 'bootstrap-icons/font/bootstrap-icons.css';


export const Footer = () => {
    return (
        <>
            <section className='bg-dark text-light py-5'>
                <div className="container ">
                    <div className="d-flex flex-row justify-content-center mb-3">
                        <div className="p-2">
                            <img src={imgLogo} alt="" className="img-fluid" width="70" height="70"/>
                        </div>
                        {/* <div className="p-2">
                            <h2 className='text-center fw-bold fst-italic'>Siguenos en </h2>
                        </div> */}
                        <div className="p-2 ">
                            <i className="bi bi-instagram h3 fst-italic" onClick={() => window.location.href="https://www.instagram.com/old.sushi/"}> Old.Sushi </i>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <i className="bi bi-alarm h5"> Nuestros horarios de atención:</i>
                            <hr />
                            <p>Lunes a Jueves desde las 13:00 a 22:30 hrs</p>
                            <p>Viernes y Sábado desde las 14:30 a 21:00 hrs</p>

                        </div>

                        <div className="col-12 col-lg-3">
                            <i className="bi bi-geo-alt h5"> Ubicación</i>
                            <hr />
                            <p>Av. La Florida 10281, Santiago.</p>
                            <p>Recuerda que puedes <strong>agendar con anticipación</strong> en el local.</p>
                        </div>
                        <div className="col-12 col-lg-3">
                            <i className="bi bi-phone-vibrate h5"> Pide tu delivery</i>
                            <hr />
                            <p>Telefono Fijo: +56 2 3270 2021</p>
                        </div>
                        <div className="col-12 col-lg-3">
                            <i className="bi bi-credit-card h5"> Medios de pago</i>
                            <hr />
                            <p>Transbank y efectivo.
                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

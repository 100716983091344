import { Navigation } from './routes/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  return (
    <>
      <Navigation></Navigation>

    </>
  );
}

export default App;

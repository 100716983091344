import React from 'react'
import '../assets/css/custom.css';
import imgCarta from '../assets/images/carta.jpg';
import imgRelleno from '../assets/images/rellenos.jpg';

export const SectionSushi = () => {
    return (
        <>
            <section className='text-dark py-5 sectionSushi'>
                <div className="container ">                    
                <div className="d-flex flex-row justify-content-center mb-2">
                        <div className="p-2">
                            <i className="bi bi-menu-up display-4 mb-5 fw-bold fst-italic"> Carta</i>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-xs-12 col-mg-6 col-lg-6 col-xl-6">
                            <img src={imgCarta} alt="" className='img-fluid rounded' />
                        </div>
                        <div className="col-12 col-sm-12 col-xs-12 col-mg-6 col-lg-6 col-xl-6">
                            <img src={imgRelleno} alt="" className='img-fluid rounded' />
                        </div>
                        {/* <div className="col-12 col-sm-12 col-xs-12 my-sm-5 my-xs-5 col-mg-4 col-lg-4 col-xl-4 bg-VerdeSushi rounded text-dark text-center ">
                            <h5 className='fw-bold my-2'>INGREDIENTES DE PRIMER NIVEL</h5>
                            <hr />
                            <p className='my-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Reiciendis, explicabo illum, consectetur eveniet obcaecati eius praesentium
                                saepe nesciunt est adipisci illo,
                                officia veniam natus eaque dolore quos voluptates sequi quia.
                            </p>
                        </div> */}

                    </div>
                </div>
            </section>
        </>
    )
}

import React from 'react'
import { Carousel } from 'react-bootstrap'

import img1 from '../assets/images/slider01.jpg';
import img2 from '../assets/images/slider02.jpg';
import img3 from '../assets/images/slider03.jpg';
import img4 from '../assets/images/slider/promo1.png';
import img5 from '../assets/images/slider/promo2.png';
import img6 from '../assets/images/slider/promo3.png';

export const CarruselImagenes = () => {
    return (
        <>
            <Carousel fade>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img4}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        {/* <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img6}
                        alt="Third slide"
                    />

                    <Carousel.Caption>
                        {/* <h3>Third slide label</h3>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

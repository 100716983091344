import React, { useEffect, useState } from 'react';
import { routes } from './routes';
import { Button, Container, Form, FormControl, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import imgDol from '../assets/images/iconoDemo.svg';
import imgLogo from '../assets/images/OLDSUSHI_01.png';
import {
    HashRouter as Router,
    Route,
    Routes,
    Link,
    Navigate
} from 'react-router-dom';
import '../assets/css/custom.css';
import { Encuentranos, Home, Carta } from '../pages';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const Navigation = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Router >

            <Navbar bg="dark" className='navbar-dark' expand={false} fixed={'top'}>
                <Container fluid>
                    <Navbar.Brand href="/" className=''>
                        <img src={imgLogo} alt="" width="30" height="24" />
                        OLD SUSHI</Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="start"
                        show={show}
                        onHide={handleClose}
                    >
                        <Offcanvas.Header closeButton>
                            <img src={imgLogo} alt="" width="50" height="50" className='img-fluid' />
                            <Offcanvas.Title id="offcanvasNavbarLabel" className='text-dark'>OLD SUSHI</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className='bg-dark'>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Link to="/" className='nav-link-sushi' onClick={handleClose} >Inicio</Link>
                                <Link to="/carta" className='nav-link-sushi' onClick={handleClose} >Nuestra Carta</Link>
                                <Link to="/encuentranos" className='nav-link-sushi' onClick={handleClose} >Encuentranos en</Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <Routes>

                <Route path="/" element={<Home />} />
                <Route path="/carta" element={<Carta />} />
                <Route path="/encuentranos" element={<Encuentranos />} />
                <Route path="/*" element={<Navigate to={"/"} replace />} />

            </Routes>
        </Router>

    )
}

import React from 'react'
import { Carousel, CarouselItem, Col, Container, Row } from 'react-bootstrap'
import { CarruselImagenes } from '../components/CarruselImagenes'
import { Servicios } from '../components/Servicios';
import { TarjetaBG } from '../components/TarjetaBG';
import { TarjetaBgImg } from '../components/TarjetaBgImg';
import { Maps } from '../components/Maps';
import { Footer } from '../components/Footer';

export const Home = () => {
    return (
        <>
            <section className='mt-5'>
                <CarruselImagenes />
            </section>
            <Servicios/>
            <Footer/>
        </>
    )
}
